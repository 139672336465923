import React, { useState, useEffect } from 'react'
import { FullPage, TextHeader, useNotifications, Loader } from '@wallyhealth/web-core'
import { useNavigate } from 'react-router-dom'
import classes from 'classnames'
import { useBookVrdhCall } from '../hooks'
import styles from './index.module.css'
import intelService from 'services/intelService'
import SlotsWrapper from 'components/schedule/SlotsWrapper'
import BookingAction from 'components/schedule/BookingAction'

const PAGE_NAME = 'VrdhBookPage'

const VrdhBookPage = ({ slots, isLoadingSlots, isFirstLoad, onTryLoadSlotsAgain, encodedPatientId }) => {
  const [isBooking, setIsBooking] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState({})

  const onBookingFailed = () => {
    setIsBooking(false)
    setSelectedSlot({})
    notifications.error('Oops, something went wrong. Please try again.')
  }

  const { bookCall } = useBookVrdhCall({ onBookingFailed })
  const notifications = useNotifications()
  const navigate = useNavigate()

  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  const onSlotSelected = ({ slot }) => {
    setSelectedSlot(slot)
    setIsBooking(true)
  }

  const bookSlot = async () => {
    try {
      intelService.recordClick({
        clickName: 'BookVrdhSlot',
        viewName: PAGE_NAME
      })

      await bookCall({
        encodedPatientId,
        vrdhId: selectedSlot.vrdhId,
        startTimeUtc: selectedSlot.startTimeUtc
      })

      navigate('/vrdh/booked')
    } catch { }
  }

  const onBookingCanceled = () => {
    setIsBooking(false)
    setSelectedSlot({})

    intelService.recordClick({
      clickName: 'CancelVrdhBooking',
      viewName: PAGE_NAME
    })
  }

  return (
    <FullPage bodyClassName={styles.container}>

      {!isFirstLoad &&
        <TextHeader
          title="Let's find a time that works for you."
          titleClassName={styles.title}
          className={styles.header}
        />
      }

      {!isLoadingSlots &&
        <SlotsWrapper
          className={styles.slots}
          slots={slots}
          onSlotSelected={onSlotSelected}
          selectedTimeSlot={selectedSlot}
          onTryLoadSlotsAgain={onTryLoadSlotsAgain}
          isFirstLoad={false}
          maxDailySlots={15}
        />
      }

      {isLoadingSlots &&
        <Loader
          isFadeInEnabled={true}
          className={classes({
            [styles.loader]: true,
            [styles.loader_init]: isFirstLoad
          })}
        />
      }

      {isBooking &&
        <BookingAction
          onCanceled={onBookingCanceled}
          onTriggered={bookSlot}
        />
      }

    </FullPage>
  )
}

export default VrdhBookPage
