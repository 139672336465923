import { useMutation, useQuery } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { fetchVrdhSlots, bookVrdhCall } from './api'

const mapSlot = (it) => {
  const date = moment.utc(it.startTimeUtc)

  return {
    id: uuidv4(),
    vrdhId: it.vrdhId,
    localDate: date.format('YYYY-MM-DD'),
    startDateTimeLocal: date.local().format('YYYY-MM-DDTHH:mm:ss'),
    startTimeUtc: it.startTimeUtc
  }
}

export const useVrdhSlots = ({ encodedPatientId }) => {
  const { data, isLoading: isLoadingSlots, isRefetching: isRefetchingSlot, isError: hasSlotsError, refetch: refetchSlots } = useQuery({
    queryKey: ['fetchVrdhSlots', encodedPatientId],
    queryFn: async () => {
      await new Promise(resolve => setTimeout(resolve, 300))
      return await fetchVrdhSlots({ encodedPatientId })
    },
    retry: 2,
    select: (items) => items.map(mapSlot),
    enabled: !!encodedPatientId
  })

  return {
    slots: data || [],
    isLoadingSlots: isLoadingSlots || isRefetchingSlot,
    isFirstLoad: isLoadingSlots && !data,
    hasSlotsError,
    refetchSlots
  }
}

export const useBookVrdhCall = ({ onBookingFailed }) => {
  const bookCallMutation = useMutation({
    queryKey: ['bookVrdhCall'],
    mutationFn: async ({ encodedPatientId, vrdhId, startTimeUtc }) => {
      await bookVrdhCall({ encodedPatientId, vrdhId, startTimeUtc })
    },
    onError: () => {
      onBookingFailed()
    }
  })

  return {
    bookCall: (data) => bookCallMutation.mutateAsync(data)
  }
}
