import React, { useEffect } from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'
import intelService from 'services/intelService'

const PAGE_NAME = 'VrdhBookedPage'

const VrdhBookedPage = () => {
  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  return (
    <ConfirmationPage
      title="Your call has been scheduled."
    />
  )
}

export default VrdhBookedPage
