import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import VrdhBookFlow from 'flows/vrdh/book'
import VrdhBookedPage from 'flows/vrdh/booked/VrdhBookedPage'
import ConfirmationPage from 'flows/confirm'
import ConfirmationSuccessPage from 'flows/confirm/ConfirmationSuccessPage'
import JoinFlow from 'flows/join'
import ActivationFlow from 'flows/activation'
import ScheduleFlow from 'flows/schedule'
import ScheduledPage from 'flows/schedule/ScheduledPage'
import WelcomePage from 'pages/WelcomePage'
import CardUpdatePage from 'pages/CardUpdatePage'
import CardUpdatedPage from 'pages/CardUpdatedPage'
import WhiteningPage from 'pages/WhiteningPage'
import NightGuardPage from 'pages/NightGuardPage'
import config from 'config'
import StandbyScheduleFlow from 'flows/standby-schedule'
import StandbyScheduleConfirmation from 'flows/standby-schedule/confirm'
import PlanComparisonPage from 'pages/PlanComparisonPage'

const RedirectToHomePage = () => {
  window.location = config.HOMEPAGE_URL
  return null
}

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route index element={<RedirectToHomePage />} />
      <Route path="join" element={<JoinFlow />} />
      <Route path="schedule" element={<ScheduleFlow />} />
      <Route path="schedule/done" element={<ScheduledPage />} />
      <Route path="standby-schedule" element={<StandbyScheduleFlow/>} />
      <Route path="standby-schedule/confirmed" element={<StandbyScheduleConfirmation/>} />
      <Route path="guides/whitening" element={<WhiteningPage />} />
      <Route path="guides/nightguard" element={<NightGuardPage />} />
      <Route path="vrdh/book" element={<VrdhBookFlow />} />
      <Route path="vrdh/booked" element={<VrdhBookedPage />} />
      <Route path="welcome" element={<WelcomePage />} />
      <Route path="card" element={<CardUpdatePage />} />
      <Route path="card/updated" element={<CardUpdatedPage />} />
      <Route path="confirm" element={<ConfirmationPage />} />
      <Route path="confirm/success" element={<ConfirmationSuccessPage />} />
      <Route path="activate/:slug" element={<ActivationFlow />} />
      <Route path="compare" element={<PlanComparisonPage />} />
      <Route path='*' element={<RedirectToHomePage />} />
    </Routes>
  </BrowserRouter>
)

export default Router
