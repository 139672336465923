import { useEffect, useState, useCallback } from 'react'
import { groupBy, isEmpty, sort, sortBy, toPairs, uniqBy, prop, take } from 'ramda'

const groupSlotsByLocalDate = groupBy(slot => slot.localDate)

const resolveUniqueTimesByStartTime = (times) => uniqBy(prop('startDateTimeLocal'))(times)

const compareDates = (slot1, slot2) => {
  return new Date(slot1.localDate).getTime() - new Date(slot2.localDate).getTime()
}
const sortByDate = (slots) => sort(compareDates, slots)

const orderTimesByStartTime = (times) => sortBy(prop('startDateTimeLocal'))(times)

const useSchedule = ({ slots = [], maxDailySlots }) => {
  const [dailySlotGroups, setDailySlotGroups] = useState([])
  const [uniqueTimeSlots, setUniqueTimeSlots] = useState([])

  const tryMapToDailySlots = () => {
    if (!isEmpty(slots)) {
      mapToDailySlots()
    }
  }
  const mapToDailySlots = useCallback(() => {
    const slotsByDate = toPairs(groupSlotsByLocalDate(slots))
      .map(([localDate, slots]) => ({ localDate, slots }))

    const sortedSlotsByDate = sortByDate(slotsByDate)
    setDailySlotGroups(sortedSlotsByDate)
  }, [slots])

  useEffect(() => {
    tryMapToDailySlots()
  }, [slots])

  const tryTakeMaxDailySlots = (orderedUniqueTimes) => {
    return maxDailySlots ? take(maxDailySlots, orderedUniqueTimes) : orderedUniqueTimes
  }

  const resolveAvailableTimes = useCallback(({ localDate }) => {
    const group = dailySlotGroups.find(g => g.localDate === localDate)
    const uniqueTimes = resolveUniqueTimesByStartTime(group.slots)
    const orderedUniqueTimes = orderTimesByStartTime(uniqueTimes)
    setUniqueTimeSlots(tryTakeMaxDailySlots(orderedUniqueTimes))
  }, [dailySlotGroups])

  return {
    dailySlotGroups,
    uniqueTimeSlots,
    resolveAvailableTimes,
    areSlotsEmpty: isEmpty(dailySlotGroups),
    hasSlots: !isEmpty(dailySlotGroups)
  }
}

export default useSchedule
