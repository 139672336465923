import { useQuery, useMutation } from '@tanstack/react-query'
import config from '../../config'
import { fetchOpenSlots, bookAppointment } from './api'

const redirectToForm = ({ path, token }) => {
  window.location.replace(`${config.INFO_WEB_URL}/${path}?t=${token}`)
}

const hasIncompleteForm = (form) => {
  return form && !form.isCompleted
}

export const useSlots = ({ linkId, locationId = null }) => {
  const { data, isLoading, isRefetching, isError, error, refetch } = useQuery({
    queryKey: ['fetchSlots', linkId, locationId],
    queryFn: async () => {
      await new Promise(resolve => setTimeout(resolve, 300))
      return await fetchOpenSlots({ linkId, locationId })
    },
    select: (result) => {
      if (hasIncompleteForm(result?.form)) {
        redirectToForm(result.form)
        return
      }
      return ({
        link: result.link,
        slots: result.slots || [],
        location: result.location,
        locationId: result.location.id,
        form: result.form
      })
    },
    enabled: !!linkId
  })

  return {
    isFetchingSlots: isLoading || isRefetching,
    hasSlotsFetchErrored: isError,
    isInvalidLinkId: error?.status === 400,
    slots: data?.slots || [],
    link: data?.link,
    location: data?.location,
    slotsLocationId: data?.locationId || locationId,
    isFirstLoad: isLoading && !data,
    refetchSlots: refetch
  }
}

export const useAppointments = () => {
  const mutation = useMutation({
    mutationFn: bookAppointment
  })

  return {
    hasBookingFailed: mutation.isError,
    bookAppointment: (data) => mutation.mutateAsync(data),
    isBooking: mutation.isPending
  }
}
