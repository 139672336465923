const facebookService = {

  recordEvent: ({ eventName, eventDetails = {}, eventId }) => {
    if (window.fbq) {
      window.fbq('track', eventName, eventDetails, { eventID: eventId })
    }
  },

  recordAddToCartEvent: ({ eventId }) => {
    facebookService.recordEvent({
      eventName: 'BagIt',
      eventId
    })
  },

  recordPurchaseEvent: ({ eventId }) => {
    facebookService.recordEvent({
      eventName: 'Celebrate',
      eventId
    })
  }
}

export default facebookService
