import React, { useState } from 'react'
import { StyledErrorPage } from '@wallyhealth/web-core'
import useQuery from '../../hooks/useQuery'
import { useSlots } from './hooks'
import ScheduleSlotsPage from './ScheduleSlotsPage'

const ScheduleFlow = () => {
  const [locationId, setLocationId] = useState(null)

  const query = useQuery()
  const linkId = query.get('id')
  const encodedConfirmationId = query.get('ecid')

  const {
    isFetchingSlots,
    isInvalidLinkId,
    refetchSlots,
    slots,
    slotsLocationId,
    link,
    location,
    isFirstLoad
  } = useSlots({ linkId, locationId })

  if (link?.isUsed) {
    return (
      <StyledErrorPage
        title="Hm..."
        description="Looks like this link has been used already."
      />
    )
  }

  if (link?.isDisabled) {
    return (
      <StyledErrorPage
        title="Hm..."
        description="Looks like this link has expired."
      />
    )
  }

  if (!linkId || isInvalidLinkId) {
    return (
      <StyledErrorPage
        title="Hm..."
        description="That looks like an invalid link."
      />
    )
  }

  return (
    <ScheduleSlotsPage
      link={link}
      location={location}
      slots={slots}
      onTryLoadSlotsAgain={refetchSlots}
      selectedLocationId={slotsLocationId}
      onLocationSelected={setLocationId}
      isFetchingSlots={isFetchingSlots}
      isFirstLoad={isFirstLoad}
      encodedConfirmationId={encodedConfirmationId}
    />
  )
}

export default ScheduleFlow
