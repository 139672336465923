import React from 'react'
import { StyledErrorPage } from '@wallyhealth/web-core'
import VrdhBookPage from './VrdhBookPage'
import { useVrdhSlots } from './hooks'
import useQuery from 'hooks/useQuery'

const VrdhFlow = () => {
  const query = useQuery()
  const encodedPatientId = query.get('id')

  const {
    slots,
    isLoadingSlots,
    isFirstLoad,
    hasSlotsError,
    refetchSlots
  } = useVrdhSlots({ encodedPatientId })

  if (!encodedPatientId || hasSlotsError) {
    return (
      <StyledErrorPage
        title="Oops, something went wrong."
        description="Make sure you're using the correct link and try again."
        onTryAgain={refetchSlots}
      />
    )
  }

  return (
    <VrdhBookPage
      slots={slots}
      onTryLoadSlotsAgain={refetchSlots}
      isLoadingSlots={isLoadingSlots}
      isFirstLoad={isFirstLoad}
      encodedPatientId={encodedPatientId}
    />
  )
}

export default VrdhFlow
