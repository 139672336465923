import React, { useEffect, useState, useRef, useCallback } from 'react'
import { isEmpty } from 'ramda'
import useDraggableScroll from 'use-draggable-scroll'
import classes from 'classnames'
import styles from './index.module.css'
import ScheduleTime from 'components/schedule/ScheduleTime'
import ScheduleDay from 'components/schedule/ScheduleDay'
import EmptySlots from 'components/schedule/EmptySlots'
import useSchedule from 'hooks/useSchedule'

const DAILY_SLOT_SIZE_IN_EM = 4

const SlotsWrapper = ({ slots, onSlotSelected, onDateSelected, selectedTimeSlot, onTryLoadSlotsAgain, className, isFirstLoad, canRenderPlaceholder = false, PlaceholderComponent, maxDailySlots }) => {
  const [selectedDate, setSelectedDate] = useState(null)

  const ref = useRef(null)
  const { onMouseDown } = useDraggableScroll(ref)

  const {
    dailySlotGroups,
    uniqueTimeSlots,
    resolveAvailableTimes,
    areSlotsEmpty,
    hasSlots
  } = useSchedule({ slots, maxDailySlots })

  const onDayClicked = useCallback(({ localDate }) => {
    setSelectedDate(localDate)
    resolveAvailableTimes({ localDate })
    onDateSelected?.({ localDate: new Date(localDate) })
  }, [resolveAvailableTimes])

  const autoSelectFirstDay = () => {
    if (hasSlots) {
      onDayClicked({
        localDate: dailySlotGroups[0].localDate
      })
    }
  }
  useEffect(() => {
    autoSelectFirstDay()
  }, [dailySlotGroups])

  if (areSlotsEmpty) {
    return (
      <EmptySlots
        onTryAgain={onTryLoadSlotsAgain}
        isCentered={isFirstLoad}
      />
    )
  }

  return (
    <div className={classes(styles.container, className)}>

      <div
        ref={ref}
        className={styles.days}
        onMouseDown={onMouseDown}
        style={{
          width: `${DAILY_SLOT_SIZE_IN_EM * dailySlotGroups.length}em`
        }}>

        {dailySlotGroups.map(group =>
          <ScheduleDay
            key={`schedule-day-${group.localDate}`}
            dayOfWeek={group.slots[0].dayOfWeek}
            localDate={group.localDate}
            onClick={onDayClicked}
            isSelected={group.localDate === selectedDate}
          />
        )}
      </div>

      {canRenderPlaceholder &&
        <PlaceholderComponent />
      }

      {!isEmpty(uniqueTimeSlots) &&
        <div
          className={classes({
            [styles.timeSlots]: true,
            [styles.oneColumn]: uniqueTimeSlots.length === 1,
            [styles.twoColumns]: uniqueTimeSlots.length === 2,
            [styles.threeColumns]: uniqueTimeSlots.length >= 3
          })}>

          {uniqueTimeSlots.map(slot =>
            <ScheduleTime
              key={`schedule-time-${slot.startDateTimeLocal}}`}
              time={slot.startDateTimeLocal}
              onSelected={() => onSlotSelected({ slot })}
              isSelected={slot.startDateTimeLocal === selectedTimeSlot.startDateTimeLocal}
            />
          )}

        </div>
      }
    </div>
  )
}

export default SlotsWrapper
