import { createClient } from '@wallyhealth/web-core'
import config from 'config'

const client = createClient({
  baseUrl: config.PROVIDER_KAPI_URL,
  timeout: 20000
})

export const fetchVrdhSlots = ({ encodedPatientId }) => {
  return client.get(`/vrdh/appointments/links/slots?id=${encodedPatientId}`)
    .then(response => response.data)
}

export const bookVrdhCall = ({ encodedPatientId, vrdhId, startTimeUtc }) => {
  const url = `/vrdh/appointments/links/book?id=${encodedPatientId}`

  const body = {
    vrdhId,
    startTimeUtc
  }
  return client.post(url, body)
    .then(response => response.data)
}
